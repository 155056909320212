import React from 'react'
import ReactDOM from 'react-dom'
import dotenv from 'dotenv'
import { CookiesProvider } from 'react-cookie'
import * as serviceWorker from './serviceWorker'
import App from '@components/App'
import './index.css'

dotenv.config()

ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById('root'),
)

serviceWorker.unregister()
