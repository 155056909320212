import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Switch, Route } from 'react-router'
import CustomRedirect from '@components/CustomRedirect'
import Spinner from '@components/Loading'
const Agro = React.lazy(() => import('@/pages/Agro'))
const Routes = () =>
{
  return (
    <BrowserRouter>
      <Switch>
        <React.Suspense fallback={<Spinner></Spinner>}>
          <Route exact path="/" component={Agro} />
        </React.Suspense>
        <CustomRedirect />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
